import { Form, Checkbox } from 'antd';
import { useField } from 'formik';
import * as React from 'react';
import { CheckboxProps } from 'antd/lib/checkbox';

interface Props extends CheckboxProps {
  label?: React.ReactNode;
  extra?: React.ReactNode;
  name: string;
}

export function CheckboxField({ extra, label, ...inputProps }: Props) {
  const { name } = inputProps;
  const [{ value, ...field }, meta] = useField(name);
  const showError = meta.touched && meta.error;
  return (
    <Form.Item
      extra={extra}
      validateStatus={showError ? 'error' : null}
      help={showError && meta.error}
    >
      <Checkbox {...field} {...inputProps} checked={value}>
        {label}
      </Checkbox>
    </Form.Item>
  );
}
