import React from 'react';

import { createApiFetch, createApiWebSocketCreator } from '../utils';

import { ApiContext } from './ApiContext';
import { Feature } from './types';

interface Props {
  apiUrl: string;
  children: React.ReactNode;
}

export function ApiProvider({ apiUrl, children }: Props) {
  const [serverFeatures, setServerFeatures] = React.useState(
    () => new Set<Feature>(),
  );

  const [apiFunctions, setApiFunctions] = React.useState(() => ({
    apiFetch: createApiFetch(apiUrl, setServerFeatures),
    createApiWebSocket: createApiWebSocketCreator(apiUrl),
  }));

  React.useEffect(() => {
    setApiFunctions({
      apiFetch: createApiFetch(apiUrl, setServerFeatures),
      createApiWebSocket: createApiWebSocketCreator(apiUrl),
    });
  }, [apiUrl]);

  return (
    <ApiContext.Provider value={{ ...apiFunctions, serverFeatures }}>
      {children}
    </ApiContext.Provider>
  );
}
