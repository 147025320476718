import React from 'react';

import { useApi } from '../api';
import { createMergingReducer } from '../utils';

import { AuthState, AuthContext } from './AuthContext';
import { User } from './types';
import { useAuthReducerWithPersistedToken } from './useAuthReducerWithPersistedToken';
import { createAuthedFetch } from './createAuthedFetch';

interface Props {
  children: React.ReactNode;
}

type Action =
  | { type: 'checkToken' }
  | { type: 'checkTokenCancel' }
  | { type: 'loginSuccess'; user: User; token: string }
  | { type: 'checkTokenFailure' }
  | { type: 'logout' };

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
  token: null,
};

const reducer = createMergingReducer<AuthState, Action>({
  checkToken: () => ({ isLoading: true }),
  checkTokenCancel: () => ({ isLoading: false }),
  loginSuccess: (_s, { token, user }) => ({
    isAuthenticated: true,
    isLoading: false,
    token,
    user,
  }),
  checkTokenFailure: () => ({ isLoading: false }),
  logout: () => ({
    isAuthenticated: false,
    isLoading: false,
    token: null,
    user: null,
  }),
});

export function AuthProvider({ children }: Props) {
  const { apiFetch } = useApi();

  const [state, dispatch] = useAuthReducerWithPersistedToken<AuthState, Action>(
    reducer,
    initialAuthState,
  );

  React.useEffect(() => {
    async function fetchUser() {
      dispatch({ type: 'checkToken' });

      if (state.user || !state.token) {
        dispatch({ type: 'checkTokenCancel' });
        return;
      }

      try {
        const res = await apiFetch('/auth/me', {
          headers: { Authorization: `Bearer ${state.token}` },
        });

        const user = await res.json();

        dispatch({ type: 'loginSuccess', user, token: state.token });
      } catch (e) {
        dispatch({ type: 'checkTokenFailure' });
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    fetchUser();
  }, [apiFetch, dispatch, state.user, state.token]);

  // TODO: use cookie auth for WS and uncomment
  //
  // React.useEffect(() => {
  //   if (!state.token) {
  //     return () => {};
  //   }

  //   const ws = createApiWebSocket('/auth/subscribe-expiration');

  //   ws.addEventListener('close', () => {
  //     // if we log out immediately on expiry, it seems like with clock skew sometimes the re-try might work again, leaving us in a weird state
  //     setTimeout(() => {
  //       dispatch({ type: 'logout' });
  //     }, LOGOUT_DELAY);
  //   });

  //   ws.addEventListener('open', () => {
  //     if (!state.token) {
  //       ws.close();
  //       return;
  //     }
  //     ws.send(state.token);
  //   });

  //   return () => {
  //     ws.close();
  //   };
  // }, [createApiWebSocket, dispatch, state.token]);

  const login = React.useCallback(
    async (
      loginBody: { email: string; password: string },
      url = '/auth/login',
    ) => {
      try {
        const res = await apiFetch(url, {
          method: 'POST',
          body: JSON.stringify(loginBody),
        });
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        const { token, user } = await res.json();
        dispatch({ type: 'loginSuccess', user, token });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [apiFetch, dispatch],
  );

  const logout = React.useCallback(() => {
    dispatch({ type: 'logout' });
  }, [dispatch]);

  const authedFetch = createAuthedFetch(apiFetch, state.token);

  return (
    <AuthContext.Provider value={{ ...state, authedFetch, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
