import { Form, Input } from 'antd';
import { useField } from 'formik';
import * as React from 'react';
import { InputProps } from 'antd/lib/input';

interface Props extends InputProps {
  label?: React.ReactNode;
  extra?: React.ReactNode;
}

export function TextField({ label, ...inputProps }: Props) {
  const { name, extra } = inputProps;
  const [field, meta] = useField(name);
  const showError = meta.touched && meta.error;
  return (
    <Form.Item
      label={label}
      extra={extra}
      validateStatus={showError ? 'error' : null}
      help={showError && meta.error}
    >
      <Input {...field} {...inputProps} />
    </Form.Item>
  );
}
