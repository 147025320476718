import React from 'react';

import { Options } from './createAuthedFetch';
import { User } from './types';

export interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  token: string | null;
}

type AuthedFetch = <TReturns, TBody = {}>(
  pathname: string,
  onAuthError: (res: {}) => void,
  options?: Options<TBody>,
) => Promise<TReturns | null>;

interface AuthContextValue extends AuthState {
  authedFetch: AuthedFetch;
  login: (loginBody: { email: string; password: string }, url?: string) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<AuthContextValue>({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  token: null,
  authedFetch: () => Promise.resolve(null),
  login: () => {},
  logout: () => {},
});

export function useAuth() {
  return React.useContext(AuthContext);
}
