import { Button, Form, Icon } from 'antd';
import { useAuth } from 'common-web';
import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { TextField } from '../formFields/TextField';
import { CheckboxField } from '../formFields/CheckboxField';

export const newClusterSchema = yup.object({
  customer: yup
    .string()
    .required()
    .max(24)
    .matches(/^[a-z][a-z\d-]*$/, {
      excludeEmptyString: true,
      message: 'Only lower-cased letters, numbers, and hyphens.',
    }),
  environmentType: yup
    .string()
    .required()
    .matches(/^(demo|engineering|support|customer)$/, {
      excludeEmptyString: true,
      message: 'Must be one of "demo", "engineering", "support" or "customer',
    }),
  sensorVersion: yup.string().required(),
  sensorMajorVersion: yup.number().required(),
  packagecloudToken: yup.string().required(),
  consoleVersion: yup.string().required(),
  consoleSKU: yup.string().required(),
  kubernetesVersion: yup.string().required(),
  deployEvaluation: yup.boolean().required(),
  defaultContent: yup.boolean().required(),
  enablePolicyConfig: yup.boolean().required(),
  contentVersion: yup.string(),
});

// TODO: unify this with ClusterData in ClusterDetails
export interface CustomerData {
  customer: string;
  environmentType: string;
  sensorVersion: string;
  sensorMajorVersion: number;
  packagecloudToken: string;
  consoleVersion: string;
  consoleSKU: string;
  kubernetesVersion: string;
  enablePolicyConfig: boolean;
  deployEvaluation: boolean;
  defaultContent: boolean;
  disableConsoleQueries: boolean;
  contentVersion: string;
}

export const initialValues: CustomerData = {
  customer: '',
  environmentType: '',
  packagecloudToken: 'INSERT_TOKEN_HERE',
  consoleVersion: '4.10.0',
  consoleSKU: 'COMPLETE',
  kubernetesVersion: '1.14',
  sensorVersion: '4.10.1',
  sensorMajorVersion: 4,
  enablePolicyConfig: true,
  deployEvaluation: true,
  defaultContent: true,
  disableConsoleQueries: false,
  contentVersion: '4.10.0',
};

interface Props {
  afterSubmit: () => void;
}

export function NewClusterForm({ afterSubmit }: Props) {
  const { authedFetch } = useAuth();

  const handleSubmitNewCluster = React.useCallback(
    async (customerData: CustomerData) => {
      await authedFetch(
        '/clusters',
        () => {
          // handle auth error case
        },
        {
          method: 'POST',
          body: {
            ...customerData,
            // Branch builds are pushed with slashes replaced with underscores
            // to support Docker's tag requirements.
            consoleVersion: customerData.consoleVersion.replace('/', '_'),
            sensorVersion: customerData.sensorVersion.replace('/', '_'),
          },
        },
      );

      afterSubmit();
    },
    [afterSubmit, authedFetch],
  );

  const validVersion = 'A git tag, branch name, or 7-character commit hash.';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitNewCluster}
      validationSchema={newClusterSchema}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <TextField
            name="customer"
            type="text"
            label="Customer name"
            extra="A unique lower-case name without spaces (hyphens/dashes are okay). Maximum length 24 characters."
            prefix={<Icon type="rocket" />}
            placeholder="Customer"
            maxLength={24}
          />

          <TextField
            name="environmentType"
            type="text"
            extra="Type of environment being created, valid values are demo, engineering, support and customer"
            label="Environment type"
            prefix={<Icon type="form" />}
          />

          <TextField
            name="sensorVersion"
            type="text"
            extra={validVersion}
            label="Sensor version"
            prefix={<Icon type="tag" />}
          />

          <TextField
            name="sensorMajorVersion"
            type="number"
            extra="If deploying a git commit hash for the sensor version the major version must be specified. This value is ignored if the sensor version is in semantic version format."
            label="Sensor major version"
            prefix={<Icon type="tag" />}
          />

          <TextField
            name="consoleVersion"
            type="text"
            extra={`${validVersion} Earliest supported version is 4.0.0-rc1.`}
            label="Console version"
            prefix={<Icon type="code" />}
          />

          <TextField
            name="consoleSKU"
            type="text"
            extra="The console SKU, valid options are PROTECT, PROTECT_PLUS and COMPLETE"
            label="Console SKU"
            prefix={<Icon type="code" />}
          />

          <TextField
            name="packagecloudToken"
            type="text"
            extra="The Packagecloud token used in consoles quick install dialogue. Generate a packagecloud token at https://github.com/capsule8/access-automation/issues"
            label="Packagecloud Token"
            prefix={<Icon type="code" />}
          />

          <CheckboxField
            name="disableConsoleQueries"
            label="Disable the investigations queries tab in console"
          />

          <CheckboxField
            name="deployEvaluation"
            label="Include sensor and vulnerable containers for scenariorunner"
          />

          <CheckboxField
            name="enablePolicyConfig"
            label="Enable Policy Config polling of console by sensor"
            extra="With this enabled, the sensor limits the configurations it accepts from the console. Scenariorunner demos involving uprobe policies, kprobe policies or non-alert responses including 'kill' will not work with this option. Requires sensor 4.1.1+ and console 4.1.0+"
          />

          {values.deployEvaluation && (
            <CheckboxField
              name="defaultContent"
              label="Include default content image in sensor installation"
            />
          )}

          {values.deployEvaluation && values.defaultContent && (
            <TextField
              name="contentVersion"
              type="text"
              extra={validVersion}
              label="Default content version"
              prefix={<Icon type="tag" />}
            />
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
