import { Card, Alert, Modal, Button } from 'antd';
import * as React from 'react';

import { NewClusterForm } from './NewClusterForm';
import { ClusterList } from './ClusterList';

export function Clusters() {
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  function showModal() {
    setIsModalVisible(true);
  }

  function hideModal() {
    setIsModalVisible(false);
  }

  function afterSubmit() {
    setHasSubmitted(true);
    hideModal();
  }

  return (
    <>
      <Card>
        {hasSubmitted && (
          <Alert type="success" message="Your cluster is being created" />
        )}
        {!hasSubmitted && (
          <>
            <Button type="primary" onClick={showModal}>
              New Cluster
            </Button>
            <Modal
              title="Create new Cluster"
              visible={isModalVisible}
              onCancel={hideModal}
              footer={null}
              style={{ marginBottom: 200 }}
            >
              <NewClusterForm afterSubmit={afterSubmit} />
            </Modal>
          </>
        )}
      </Card>
      <Card>
        <ClusterList />
      </Card>
    </>
  );
}
