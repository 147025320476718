import React from 'react';

import { Feature } from './types';

export type ApiFetch = (url: string, init?: RequestInit) => Promise<Response>;

export interface ApiFunctions {
  apiFetch: ApiFetch;
  createApiWebSocket: (pathname: string) => WebSocket;
  serverFeatures: Set<Feature>;
}

export const ApiContext = React.createContext<ApiFunctions>({
  apiFetch: () => {
    throw new Error();
  },
  createApiWebSocket: () => {
    throw new Error();
  },
  serverFeatures: new Set(),
});

export function useApi() {
  return React.useContext(ApiContext);
}
