import * as React from 'react';
import { Drawer } from 'antd';
import { Grid, RowClickHandler } from 'gridiculous';
import { useAuthedFetchData } from 'common-web';
import { ClusterDetails, ClusterData } from './ClusterDetails';
import { ClusterStatus } from './ClusterStatus';

export function ClusterList() {
  const data: [ClusterData] = useAuthedFetchData('/clusters', {});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedData, setSelectedData]: [ClusterData, any] = React.useState();
  const [showDrawer, setShowDrawer] = React.useState(false);

  const handleRowClick: RowClickHandler = (event, { datum }) => {
    setShowDrawer(true);
    setSelectedData(datum);
  };

  return (
    data &&
    data.length && (
      <>
        <Grid
          data={data}
          onRowClick={handleRowClick}
          rowKey="clusterName"
          columns={[
            { key: 'clusterName', name: 'Name' },
            { key: 'sensorVersion', name: 'Sensor Version' },
            { key: 'contentVersion', name: 'Content Version' },
            { key: 'consoleVersion', name: 'Console Version' },
            { key: 'email', name: 'Owner' },
            { key: 'environmentType', name: 'Environment' },
            { key: 'status', name: 'Status', renderer: ClusterStatus },
          ]}
        />
        <Drawer
          visible={showDrawer}
          title={selectedData && selectedData.clusterName}
          onClose={() => setShowDrawer(false)}
          width={500}
        >
          <ClusterDetails
            data={selectedData}
            onDelete={() => setShowDrawer(false)}
          />
        </Drawer>
      </>
    )
  );
}
