import 'antd/dist/antd.css';
import { ApiProvider, AuthProvider } from 'common-web';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from './App';
import { constants } from './constants';

const { MANAGER_API_URL } = constants();

ReactDOM.render(
  <ApiProvider apiUrl={MANAGER_API_URL}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ApiProvider>,
  document.querySelector('#react-root'),
);
