import React from 'react';

import { FetchMethod } from '../api/types';
import { useAuth } from './AuthContext';

interface Options<TReturns> {
  method?: FetchMethod;
  onData?: (data: TReturns | null) => void;
}

export function useAuthedFetchCallback<TReturns, TBody = {}>(
  pathname: string,
  options?: Options<TReturns>,
): (body?: TBody) => Promise<TReturns | null> {
  const { method = 'GET', onData }: Options<TReturns> = options || {};
  const { authedFetch, logout } = useAuth();

  const cb = React.useCallback(
    async (body?: TBody) => {
      try {
        const data = await authedFetch<TReturns, TBody>(pathname, logout, {
          method,
          body,
        });
        if (onData) {
          onData(data);
        }
        return data;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return null;
    },
    [authedFetch, logout, method, onData, pathname],
  );

  return cb;
}
