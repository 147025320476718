import { isEqual } from 'lodash-es';
import React from 'react';

export function useDeepMemo<T>(val: T) {
  const memo = React.useRef(val);

  React.useEffect(() => {
    if (!isEqual(memo.current, val)) {
      memo.current = val;
    }
  }, [val]);

  return memo.current;
}
