import * as React from 'react';
import { Button, Form, Input, Icon } from 'antd';
import { useField } from 'formik';

import styles from './LoginForm.scss';

interface Props {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

export function LoginForm({ onSubmit }: Props) {
  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit(e);
    },
    [onSubmit],
  );

  const [emailField, emailMeta] = useField({ name: 'email' });
  const [passwordField, passwordMeta] = useField({ name: 'password' });

  const showEmailError = emailMeta.touched && emailMeta.error;
  const showPasswordError = passwordMeta.touched && passwordMeta.error;

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Form.Item
        validateStatus={showEmailError ? 'error' : null}
        help={showEmailError && emailMeta.error}
      >
        <Input
          {...emailField}
          prefix={<Icon type="user" className={styles.inputIcon} />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        validateStatus={showPasswordError ? 'error' : null}
        help={showPasswordError && passwordMeta.error}
      >
        <Input
          {...passwordField}
          prefix={<Icon type="lock" className={styles.inputIcon} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
}
