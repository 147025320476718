import { Button, Card, Layout } from 'antd';
import { Feature, useApi, useAuth } from 'common-web';
import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import logo from '../logo.svg';

import { ConditionalSpinner } from '../ConditionalSpinner';
import styles from './Login.scss';
import { LoginForm } from './LoginForm';

export const loginSchema = yup.object({
  email: yup
    .string()
    .email()
    .required('Please input your email.'),
  password: yup.string().required('Please input your password.'),
});

function useGoogle() {
  const { serverFeatures, apiFetch } = useApi();
  const [googleEnabled, setGoogleEnabled] = React.useState(false);
  React.useEffect(() => {
    async function fetch() {
      // Make any call to populate X-Features header.
      try {
        await apiFetch('/', { mode: 'cors' });
      } catch (e) {
        // Do nothing
      }

      setGoogleEnabled(serverFeatures.has(Feature.GOOGLE));
    }

    fetch();
  }, [apiFetch, serverFeatures, setGoogleEnabled]);

  const googleLogin = React.useCallback(async () => {
    try {
      const r = await apiFetch('/auth/google');
      const { url, oauthstate } = await r.json();
      window.localStorage.setItem('oauthstate', oauthstate);
      // Redirect to Google
      window.location = url;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      window.location.pathname = '';
    }
  }, [apiFetch]);

  return { googleEnabled, googleLogin };
}

export function Login() {
  const { isLoading, login } = useAuth();
  const { googleEnabled, googleLogin } = useGoogle();

  return (
    <Layout className={styles.loginWrapper}>
      <ConditionalSpinner shouldSpin={isLoading}>
        <Card className={styles.login}>
          <img className={styles.logo} src={logo} alt="Capsule8 Logo" />
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={({ email, password }) => login({ email, password })}
            validationSchema={loginSchema}
          >
            {({ submitForm }) => <LoginForm onSubmit={submitForm} />}
          </Formik>
          {googleEnabled && (
            <Button type="link" onClick={googleLogin}>
              Sign in with Google
            </Button>
          )}
        </Card>
      </ConditionalSpinner>
    </Layout>
  );
}
