import { Layout, Button } from 'antd';
import { useAuth } from 'common-web';
import * as React from 'react';

import styles from './App.scss';
import { Clusters } from './Clusters';
import { Login } from './Login';
import logoDark from './logo-dark.svg';

const { Content, Footer, Header } = Layout;

export function App() {
  const { isAuthenticated, logout, login } = useAuth();

  // TODO: replace with router5
  const isGoogleCallback =
    window.location.pathname === '/app/auth/google/callback';
  React.useEffect(() => {
    if (isGoogleCallback) {
      const oauthstate = window.localStorage.getItem('oauthstate');
      window.localStorage.setItem('oauthstate', '');
      const googleAuthUrl = `auth/google/callback${window.location.search}&oauthstate=${oauthstate}`;
      login({ email: '', password: '' }, googleAuthUrl);
      window.history.replaceState({}, null, '/app');
    }
  }, [isGoogleCallback, login]);

  if (isGoogleCallback) {
    return null;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <img src={logoDark} alt="Capsule8 Logo" />
        {isAuthenticated && (
          <Button onClick={logout} type="link">
            Logout
          </Button>
        )}
      </Header>
      <Content className={styles.content}>
        <div className={styles.container}>
          <Clusters />
        </div>
      </Content>
      <Footer className={styles.footer}>Capsule8 ©2019</Footer>
    </Layout>
  );
}
