import React from 'react';

import { FetchMethod } from '../api/types';
import { useDeepMemo } from '../utils/useDeepMemo';

import { useAuth } from './AuthContext';

interface Options<TBody> {
  method?: FetchMethod;
  body?: TBody;
}

export function useAuthedFetchData<TReturns, TBody = {}>(
  pathname: string,
  { method = 'GET', body }: Options<TBody>,
): TReturns | null {
  const [data, setData] = React.useState<TReturns | null>(null);

  const { authedFetch, logout } = useAuth();

  const bodyMemoized = useDeepMemo(body);

  React.useEffect(() => {
    async function f() {
      try {
        const newData = await authedFetch<TReturns, TBody>(pathname, logout, {
          method,
          body,
        });
        setData(newData);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    f();
  }, [body, bodyMemoized, logout, method, pathname, authedFetch]);

  return data;
}
