import { isEqual } from 'lodash-es';

import { Feature } from '../api/types';

export function createApiFetch(
  apiUrl: string,
  setServerFeatures: React.Dispatch<React.SetStateAction<Set<Feature>>>,
) {
  // TODO: accept a `search?: string` parameter. But fall back to
  // splitting the pathname for areas using it. (Currently only Google Auth)
  // in the Manager.
  return function apiFetch(pathname: string, init?: RequestInit) {
    const url = new URL(apiUrl);
    const [realPathname, ...rest] = pathname.split('?');
    url.pathname = realPathname;
    url.search = rest.length ? `?${rest.join('?')}` : '';

    const resPromise = fetch(url.toString(), init);
    resPromise.then((res) => {
      if (!res.headers.has('X-Features')) {
        return;
      }
      const xFeatures = res.headers.get('X-Features');
      if (!xFeatures) {
        return;
      }
      const features = xFeatures
        .split(',')
        .filter((f) => f in Feature) as Feature[];

      setServerFeatures((oldFeatures) => {
        const newFeatures = new Set(features);
        if (isEqual(oldFeatures, newFeatures)) {
          // otherwise fetch will cause an infinite loop, returning the same object from setstate prevents re-render
          return oldFeatures;
        }
        return newFeatures;
      });
    });

    return resPromise;
  };
}

export function createApiWebSocketCreator(apiUrl: string) {
  return function createApiWebSocket(pathname: string): WebSocket {
    const url = new URL(apiUrl);
    url.protocol = 'ws';
    url.pathname = pathname;
    return new WebSocket(url.toString());
  };
}
