/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { Button, List, Card } from 'antd';
import { useAuth } from 'common-web';
import { ClusterStatus } from './ClusterStatus';

interface ConsoleUser {
  userName: string;
  password: string;
}

interface EKS {
  kubeconfig: string;
}

interface SshKeypair {
  privateKey: string;
}

export interface ClusterData {
  clusterName: string;
  sensorVersion: string;
  contentVersion: string;
  consoleVersion: string;
  accountId: string;
  consoleUser: ConsoleUser;
  consoleDefaultAdminPassword: string;
  eks: EKS;
  sshKeypair: SshKeypair;
  email: string;
  status: string;
  paused: boolean;
  deployEvaluation: boolean;
  defaultContent: boolean;
  enablePolicyConfig: boolean;
}

export function ClusterDetails({
  data,
  onDelete,
}: {
  data: ClusterData;
  onDelete: () => void;
}) {
  const { authedFetch } = useAuth();
  const consoleURL = `https://console.${data.clusterName}.aws.cap8.io`;

  function handleDelete() {
    // eslint-disable-next-line no-alert
    const confirmed = confirm('are you sure you want to delete this cluster?');
    if (!confirmed) {
      return;
    }
    authedFetch(
      `/cluster/${data.clusterName}`,
      () => {
        // handle auth error case
      },
      { method: 'DELETE' },
    );
    onDelete();
  }

  function handlePause() {
    // eslint-disable-next-line no-alert
    const confirmed = confirm('are you sure you want to pause this cluster?');
    if (!confirmed) {
      return;
    }
    authedFetch(
      `/cluster/pause/${data.clusterName}`,
      () => {
        // handle auth error case
      },
      { method: 'POST' },
    );
  }

  function handleUnpause() {
    // eslint-disable-next-line no-alert
    const confirmed = confirm('are you sure you want to unpause this cluster?');
    if (!confirmed) {
      return;
    }
    authedFetch(
      `/cluster/start/${data.clusterName}`,
      () => {
        // handle auth error case
      },
      { method: 'POST' },
    );
  }

  return (
    <div>
      <List>
        <List.Item>
          <List.Item.Meta
            title="Status"
            description={ClusterStatus({ value: data.status })}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta title="Email" description={data.email} />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Console URL"
            description={
              <a href={consoleURL} target="_blank" rel="noreferrer noopener">
                {consoleURL}
              </a>
            }
          />
        </List.Item>
        {data.consoleDefaultAdminPassword === '' ? null : (
          <List.Item>
            <List.Item.Meta
              title="Initial Console Admin Password"
              description={data.consoleDefaultAdminPassword}
            />
          </List.Item>
        )}
        <List.Item>
          <List.Item.Meta
            title="Sensor Version"
            description={data.sensorVersion}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="Console Version"
            description={data.consoleVersion}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="AWS Account"
            description={
              <a
                href={`https://${data.accountId}.signin.aws.amazon.com/console?region=us-east-1`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {data.accountId}
              </a>
            }
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="AWS User Name"
            description={data.consoleUser.userName}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title="AWS Password"
            description={data.consoleUser.password}
          />
        </List.Item>
      </List>
      <Button
        href={`data:text/plain;base64,${data.eks.kubeconfig}`}
        download={`${data.clusterName}.kubeconfig`}
        icon="download"
        disabled={!data.eks.kubeconfig}
      >
        Download kubeconfig
      </Button>

      <Button
        href={`data:text/plain,${data.sshKeypair.privateKey}`}
        download={`${data.clusterName}.pem`}
        icon="download"
        disabled={!data.sshKeypair.privateKey}
      >
        Download SSH Key
      </Button>
      <Card
        size="small"
        title="Delete Cluster"
        style={{ width: 200, marginTop: 40 }}
      >
        <Button
          type="danger"
          onClick={handleDelete}
          disabled={[
            'CREATING',
            'UPDATING',
            'DELETING',
            'PAUSING',
            'STARTING',
          ].includes(data.status)}
        >
          Delete
        </Button>
      </Card>
      <Card
        size="small"
        title="Pause Cluster"
        style={{ width: 200, marginTop: 40 }}
      >
        <Button
          type="danger"
          onClick={handlePause}
          disabled={
            [
              'CREATING',
              'UPDATING',
              'DELETING',
              'PAUSING',
              'STARTING',
            ].includes(data.status) || data.paused === true
          }
        >
          Pause
        </Button>
        <Button
          type="danger"
          onClick={handleUnpause}
          disabled={
            [
              'CREATING',
              'UPDATING',
              'DELETING',
              'PAUSING',
              'STARTING',
            ].includes(data.status) || data.paused === false
          }
        >
          Un-pause
        </Button>
      </Card>
    </div>
  );
}
