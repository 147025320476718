/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { Icon, Typography } from 'antd';

const { Text } = Typography;

export function ClusterStatus({ value }: { value: string }) {
  if (value === 'DELETING') {
    return (
      <>
        <Icon type="delete" theme="twoTone" twoToneColor="red" /> Deleting
      </>
    );
  }
  if (value === 'CREATING') {
    return (
      <>
        <Icon type="build" theme="twoTone" twoToneColor="green" /> Creating
      </>
    );
  }
  if (value === 'UPDATING') {
    return (
      <>
        <Icon type="tool" theme="twoTone" twoToneColor="blue" /> Updating
      </>
    );
  }
  if (value === 'PAUSING') {
    return (
      <>
        <Icon type="tool" theme="twoTone" twoToneColor="blue" /> Pausing
      </>
    );
  }
  if (value === 'STARTING') {
    return (
      <>
        <Icon type="tool" theme="twoTone" twoToneColor="blue" /> Starting
      </>
    );
  }

  if (value === 'UNKNOWN') {
    return (
      <>
        <Icon type="question-circle" theme="twoTone" twoToneColor="black" />{' '}
        Unknown
      </>
    );
  }
  if (value === '') {
    return <Text type="secondary">Ready</Text>;
  }
}
