import { ApiFetch } from '../api/ApiContext';
import { FetchMethod } from '../api/types';

export interface Options<TBody> {
  method?: FetchMethod;
  body?: TBody;
}

export function createAuthedFetch(apiFetch: ApiFetch, token: string | null) {
  return async function authedFetch<TReturns, TBody = {}>(
    pathname: string,
    onAuthError: (res: Response) => void,
    options?: Options<TBody>,
  ): Promise<TReturns | null> {
    if (!token) {
      return Promise.resolve(null);
    }

    const { method = 'GET', body }: Options<TBody> = options || {};

    const res = await apiFetch(pathname, {
      method,
      headers: { Authorization: `Bearer ${token}` },
      ...(method === 'POST' || method === 'DELETE'
        ? { body: JSON.stringify(body) }
        : {}),
    });

    if (!res.ok) {
      if (res.status === 401) {
        onAuthError(res);
      }
      throw new Error(res.statusText);
    }

    return res.json();
  };
}
