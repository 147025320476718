import React from 'react';

const TOKEN_KEY = 'token';
const initialToken = localStorage.getItem(TOKEN_KEY) || null;

export function useAuthReducerWithPersistedToken<
  TState extends { token: string | null },
  TAction
>(
  reducer: React.Reducer<TState, TAction>,
  initialAuthState: TState,
): [TState, React.Dispatch<TAction>] {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialAuthState,
    token: initialToken,
  });

  React.useEffect(() => {
    if (state.token) {
      localStorage.setItem(TOKEN_KEY, state.token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
  }, [state, state.token]);

  return [state, dispatch];
}
