import { Spin } from 'antd';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  shouldSpin: boolean;
  delay?: number;
}

type SpinState = 'spin' | 'blank' | 'content';

/*
  the base behavior is conditionally showing a spinner instead of the children content
  depending on the state of `shouldSpin` prop

  this is enhanced by additional behavior that attempts to prevent the flash of a spinner
  by delaying the display of the spinner unless the user has already been waiting for `delay` ms
*/
export function ConditionalSpinner({
  children,
  className,
  shouldSpin,
  delay = 300,
}: Props) {
  const [spinState, setSpinState] = React.useState<SpinState>(() =>
    shouldSpin ? 'blank' : 'content',
  );

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (shouldSpin) {
      setSpinState('blank');
      timeout = setTimeout(() => {
        setSpinState('spin');
      }, delay);
    } else {
      setSpinState('content');
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [delay, shouldSpin]);

  if (spinState === 'blank') {
    return null;
  }

  if (spinState === 'spin') {
    return (
      <div className={className}>
        <Spin size="large" />
      </div>
    );
  }

  return <>{children}</>;
}
